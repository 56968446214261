import React, { useState, useEffect } from "react";
import axios from "axios";
import HistoryTable from "../../Components/Tables/HistoryTable";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";

function DocumentHistory() {
    const [isLoading, setIsLoading] = useState(false);
    const [documentHistories, setDocumentHistories] = useState([]);
    const [clients, setClients] = useState([]);
    const [selectedClientValue, setSelectedClientValue] = useState();
   
    useEffect(() => {
        axios
            .get("/api/v1/Products/My")
            .then((response) => {
            
                var i;
                var clients = [];
                for (i = 0; i < response.data.clients.length; i++) {
                    if (response.data.clients[i].categories.includes("Internal Applications")) {
                        clients.push(response.data.clients[i]);
                    }
                }
               setClients(clients);
            })
            .catch(function (error) {
                console.log(error.response);
            });
    }, []);

    const handleSelectClientChange = (event) => {
        let theValue = event.value;
        if(theValue)
            {
                setIsLoading(true)
                axios
                .get(`/api/v1/InternalDocument/AuditLog?clientId=${theValue}`)
                .then((response) => {
                    setIsLoading(false)
                    setDocumentHistories(response.data)
                })
                .catch(function (error) {
                    console.log(error.response)
                })
                .finally(() => {
                    setIsLoading(false)
                })
            }
        setSelectedClientValue(theValue)
    }

    return (
        <>
            <ToastContainer /> 
            <div className="container pb-5">
                <h2 className="my-5 text-center">Internal Document Storage History</h2>
                <div style={{lineHeight: "50px"}}>
                    <label style={{paddingRight: "20px", display: "inline-block"}} htmlFor="dropdown">Select Client:</label>
                    <div style={{ display: "inline-Block", width: "400px", lineHeight: "20px" }}>
                        <Select 
                            className="dropdown" 
                            placeholder="Select Option" 
                            options={clients.map((option) => (
                                {"value": option.id, "label": option.name}))}
                            singleValue={selectedClientValue}
                            onChange={handleSelectClientChange} />
                    </div>
                    <Link to="/InternalDocumentStorage">
                        <button
                            className="btn btn-primary my-2"
                            style={{float: "right"}}>
                            Document Storage
                        </button>
                    </Link>
                </div>
                {isLoading 
                ? 
                      <Loader
                        className="nothing-display"
                        type="Oval"
                        color="#0F204B"
                        height={100}
                        width={100}
                      />
                    : <HistoryTable data={documentHistories} />}
            </div>
           
        </>
    );
}


export default DocumentHistory;
