import React, { useState, useEffect } from "react"
import MyPagination from "../../MyPagination"
import MySortedTh, { SortType, onThClickHelper } from "../../MySortTh"
import { AuditLogVM, OperationTypes } from "../../../Model/Model"


function HistoryTable(props: any) {

    const [theData, setTheData] = useState<AuditLogVM[]>()
    const [sortColumnName, setSortColumnName] = useState<string>("")
    const [sortType, setSortType] = useState<string>("")
    const [searchKey, setSearchKey] = useState<string>()

    useEffect(() => {
        setTheData(props.data as AuditLogVM[]);
        setSearchKey('')
    }, [props.data]);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const changeCurrentPage = (p: number) => {
        setCurrentPage(p)
    }

    const onPageSizeChange = (p: number) => {
        setPageSize(p)
        setCurrentPage(1)
    }

    const getDisplayItems = () => {
        let theResult = Object.values(Object.assign({}, theData || []))
       
        if(searchKey)
        {
            theResult = theResult?.filter(x => x.title?.toLowerCase().includes(searchKey.toLowerCase()))
        }

        if (sortColumnName) {
            if (sortType === SortType.DESC) {
                theResult = (theResult as any[]).sort((a, b) => (b[sortColumnName] || "").toString().localeCompare(a[sortColumnName] || ""))
            }
            else {
                theResult = (theResult as any[]).sort((a, b) => (a[sortColumnName] || "").toString().localeCompare(b[sortColumnName] || ""))
            }
        }

        if (totalCount !== theResult.length) {
            setTotalCount(theResult.length)
        }

        return theResult
    }

    const [totalCount, setTotalCount] = useState<number>()
    const onSearchChange = (e: any) => {
        setSearchKey(e.target.value)
        setCurrentPage(1)
      }

    return (
        <div>
            <div className="button-container float-left"><input className="searchButton" onChange={(e) => onSearchChange(e)} placeholder="Search" value={searchKey}></input></div>
            <table className="dataListTbMainList">
                <thead>
                    <tr>
                        <MySortedTh
                            CurrentSortName={sortColumnName}
                            SortAble={true}
                            SortColumnName={'title'}
                            SortType={sortType}
                            Style={{ cursor: 'pointer' }}
                            Title="Title"
                            onThClick={() => onThClickHelper(sortColumnName, sortType, 'title', setSortColumnName, setSortType)}
                        />
                        <MySortedTh
                            CurrentSortName={sortColumnName}
                            SortAble={true}
                            SortColumnName={'fileName'}
                            SortType={sortType}
                            Style={{ cursor: 'pointer' }}
                            Title="File Name"
                            onThClick={() => onThClickHelper(sortColumnName, sortType, 'fileName', setSortColumnName, setSortType)}
                        />
                        
                        <MySortedTh
                            CurrentSortName={sortColumnName}
                            SortAble={true}
                            SortColumnName={'userName'}
                            SortType={sortType}
                            Style={{ cursor: 'pointer' }}
                            Title="User Name"
                            onThClick={() => onThClickHelper(sortColumnName, sortType, 'userName', setSortColumnName, setSortType)}
                        />
                        <MySortedTh
                            CurrentSortName={sortColumnName}
                            SortAble={true}
                            SortColumnName={'operationType'}
                            SortType={sortType}
                            Style={{ cursor: 'pointer' }}
                            Title="Operation Type"
                            onThClick={() => onThClickHelper(sortColumnName, sortType, 'operationType', setSortColumnName, setSortType) }
                        />
                        <MySortedTh
                            CurrentSortName={sortColumnName}
                            SortAble={true}
                            SortColumnName={'dateTime'}
                            SortType={sortType}
                            Style={{ cursor: 'pointer' }}
                            Title="Date"
                            onThClick={() => onThClickHelper(sortColumnName, sortType, 'dateTime', setSortColumnName, setSortType) }
                        />
                    </tr>
                </thead>
                <tbody>
                    {getDisplayItems() && (getDisplayItems() as AuditLogVM[]).slice((currentPage - 1) * pageSize, currentPage * pageSize)
                        && (getDisplayItems() as AuditLogVM[]).slice((currentPage - 1) * pageSize, currentPage * pageSize).map((item: AuditLogVM) => {
                       
                            return <tr key={item.id}>
                                <td>{item.title}</td>
                                <td>{item.fileName}</td>
                                <td title={item.userId}>
                                    {item.userName}
                                </td>
                                <td>{OperationTypes[item.operationType]}</td>
                                <td>{(new Date(item.dateTime)).toLocaleDateString()}</td>
                            </tr>
                        })}
                </tbody>
            </table>
            {totalCount && <MyPagination totalCount={totalCount} onPageSizeChange={(p) => onPageSizeChange(p)} currentPage={currentPage}
                onClick={(p) => changeCurrentPage(p)} />}
        </div>
    );
}

export default HistoryTable;
